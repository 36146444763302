.container {
    width: 100vw;
    height: calc(100vh - var(--height-header));
    display: flex;
    align-items: center;
    justify-content: center;

    .loading {
        position: relative;
        width: 40px;
        height: 40px;
        border: 4px solid transparent;
        border-color: green transparent green;
        border-radius: 50%;
        animation: spinner 1s ease infinite;
    }
}

@keyframes spinner {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}
