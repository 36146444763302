.home {
    min-height: calc(100vh - 66px);

    @media (min-width: 768px) {
        border-left: 50px solid #5a308d;
    }
}
.wrapper {
    max-width: 521px;
}
.paginate {
    background-color: #fff;
    padding: 16px;
}
.paginate-item {
    padding: 6px;
    cursor: pointer;
}

.paginate-item.active {
    background-color: #ccc;
}
