.menu {
    position: relative;
    width: 250px;
    min-height: 200px;
    padding: 8px;
    border: 1px solid #eee;
    background-color: #fff;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

    .item {
        padding: 8px 4px;
        &:hover {
            background-color: #ccc;
        }
    }
    .link {
        width: 100%;
        display: block;
    }

    &::before {
        content: "";
        width: 200px;
        height: 40px;
        background-color: transparent;
        position: absolute;
        top: -40px;
        right: 0;
    }
}
