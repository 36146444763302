.ck-content {
    line-height: 2.5rem;
    h1 {
        font-weight: 700;
        font-size: 18px;
        padding: 8px 0;
    }

    p {
        padding: 4px 0;
    }

    ul,
    ol {
        margin-left: 26px;
    }

    li {
        margin: 8px 0;
    }
}
