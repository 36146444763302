.header {
    display: flex;
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    height: 66px;
}

.header-content {
    gap: 20%;
    justify-content: space-between;

    .action {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }

    .search-box {
        display: flex;
        align-items: center;
        width: 500px;
        max-width: 100%;
        border: 1px solid #ccc;
        border-radius: 999px;
        overflow: hidden;
        padding: 0 12px;
    }
    .icon-search {
        color: #bbb;
    }

    .input-search {
        flex: 1;
        outline: none;
        border: none;
        font-size: 16px;
        padding: 8px;
    }
}
