.post-list {
    margin-top: 24px;
}
.post-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}
.table {
    margin-top: 20px;
    border-collapse: collapse;
}

.th,
.td,
.table {
    border: 1px solid;
}

.th {
    font-weight: 600;
    font-size: 18px;
}

.td,
.th {
    padding: 8px 16px;
}
.paginate {
    background-color: #fff;
    padding: 16px;
}
.paginate-item {
    padding: 6px;
    cursor: pointer;
}

.paginate-item.active {
    background-color: #ccc;
}
