.mt-20 {
    margin-top: 20px;
}

.main-container {
    max-width: 50%;

    @media (max-width: 768px) {
        max-width: inherit;
    }
}

.header {
    display: flex;
    align-items: center;
}
.btn {
    background-color: orange;
    min-width: 100px;
    padding: 8px 0;
    border: none;
    outline: none;
    border-radius: 5px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
}

.content {
    border: 1px solid #ccc;
    border-radius: 5px;
}
.editor {
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}
.input-title {
    width: 100%;
    margin: 4px 0;
    padding: 8px;
    font-size: 20px;
    outline: none;
    border: none;
}

.show-content {
    margin-top: 52px;
    padding: 0 10px;
    width: 50%;
}

.content {
    img {
        width: 100%;
        height: 100%;
    }
}
