.post-item {
    padding: 10px;
    margin-bottom: 20px;
    background-color: #fff;
}
.post-title {
    padding: 16px 0;
    font-weight: 600;
    font-size: 18px;
}

.post-content {
    p {
        padding: 8px 0;
    }

    img {
        display: block;
        width: 100%;
    }
}
