@import "reset.scss";
@import "grid";
@import "./ck-content.scss";
* {
    box-sizing: border-box;
}

:root {
    --height-header: 66px;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 16px;
    font-family: "Montserrat", serif;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    cursor: pointer;
}

input:-internal-autofill-selected {
    background-color: transparent !important;
}

.content {
    width: min(1110px, calc(100% - 48px));
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ck-content h1 {
    font-size: 18px;
    font-weight: 700;
}
