.avatar {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
}
.inner {
    margin-top: 20px;
}

.title {
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0;
}

.btn-submit {
    width: 80px;
    padding: 8px;
    background-color: rgb(6, 255, 68);
    border: none;
    color: white;
    border-radius: 5px;
}

.password {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input-text {
    padding: 8px;
    outline: none;
    max-width: 200px;
}
