.content {
    margin: 0 24px;
    @media (max-width: 576px) {
        padding: 0;
        margin: 0;
    }

    @media (max-width: 768px) {
        margin: 0 6px;
    }
}

.form-auth {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    height: calc(100vh - 266px);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.left {
    flex: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 40px;
    gap: 30px;

    @media (max-width: 768px) {
        padding: 20px;
    }

    @media (max-width: 576px) {
        padding: 0px;
    }

    .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
    }

    .form-control {
        position: relative;
        border-radius: 5px;
        width: 100%;
    }

    .label {
        position: absolute;
        top: 50%;
        left: 0;
        padding: 0 8px;
        margin-left: 4px;
        font-size: 16px;
        transform: translateY(-50%);
        transition: top ease 0.2s;
    }

    .input {
        font-size: 16px;
        padding: 20px 12px;
        width: 100%;
        border: 1px solid #ddd;
        outline: none;
        background-color: transparent;
    }

    .input:focus + .label,
    .input:not(:placeholder-shown) + .label {
        font-weight: 500;
        top: 0;
        left: 0;
        background-color: #fff;
    }

    .heading {
        font-size: 5rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 25px;
    }

    .title {
        text-align: center;
    }

    .icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .icon-social {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        text-align: center;
        line-height: 35px;
        color: #fff;
        font-size: 20px;
    }

    .icon-in {
        background-color: #0f76aa;
    }
    .icon-gg {
        background-color: #d95139;
    }
    .icon-fb {
        background-color: #3a5897;
    }

    .btn {
        background-color: #059669;
        border: none;
        font-size: 16px;
        color: #fff;
        font-weight: 500;
        font-family: inherit;
        padding: 24px 0;
        border-radius: 5px;
        width: 100%;
    }
}

.right {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(45deg, #31cf96, #059669);
    color: #fff;
    height: 100%;
    padding: 0 2.4rem;
    font-size: 2rem;
    line-height: 3rem;
    gap: 24px;

    @media (max-width: 576px) {
        display: none;
    }

    .title {
        text-align: center;
        font-size: 3.5rem;
        font-weight: 600;
    }

    .btn-register {
        padding: 16px;
        background: linear-gradient(45deg, #31cf96, #059669);
        border-radius: 999px;
        font-size: 1.6rem;
    }
}

.forgot-password {
    margin-top: 20px;
}

.error {
    color: red;
    font-size: 1.4rem;
    text-align: center;
}
