.paginate {
    background-color: #fff;
    padding: 16px;
}
.paginate-item {
    padding: 6px;
    cursor: pointer;
}

.paginate-item.active {
    background-color: #ccc;
}
